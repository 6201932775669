import styled, { css } from "styled-components";
import { ModalTitle, Tabs, Modal } from 'react-bootstrap';
import { ImageInput } from '../CreateEvent/styles';
const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const StyledTabContainer = styled(Tabs)`
   float: left;
   width: 100%;
  > ul {
    padding: 20px 20px 1px 33px;
    font-size: 12px;
    text-transform: uppercase;
    color: rgb(153,153,153);
    background: ${({ bgColor }) => bgColor ? bgColor : '#fff'};
    border-radius: 6px;
    margin: 0 37px 0 32px;
    
    > li {
      > a {
        font-size: 12px;
        letter-spacing: 0px;
        color: rgb(153,153,153);
        font-family: ${BoldFont};
        margin-right: 5vw;
        text-transform: uppercase;
        padding: 5px 5px 17px 5px;
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border-bottom: none;
        }
      }
    }
    
    .active {
      border-bottom: none;
      > a {
        background-color: transparent;
        color: rgb(21,159,202);
        border: none;
        border-bottom: 3px solid rgb(21,159,202);
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border: none;
          border-bottom: 3px solid rgb(21,159,202);
          color: rgb(21,159,202);
        }
      }
    }
  }
`;

const SocialFeedsContainer = styled.div`
  float: left;
  width: ${({ webinar }) => webinar ? '100%' : '65%'};
  margin-right: ${({ videoCSS }) => videoCSS ? '0px' : '25px'};
  max-width: 836px;
  >div:nth-child(2){
    float: left;
    width: 100%;
    margin-top: 25px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const ScrollableFeedsContainer = styled.div`
  width: 100%;
  float: left;
  height: 1550px;
  // overflow-x: hidden;
  // overflow-y: auto;

  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    
    @media (max-width: 500px) {
      width: 5px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: #c7c8c7;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: white;
  }
`;

const InputFieldContainer = styled.div`
  width: ${({ UpdateStatus, survey, width }) => UpdateStatus || survey || width ? '100%' : 'calc(100% - 58px)'};
  float: left;
  display: flex;
  background: white;
  -moz-box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  -webkit-box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  height: 'auto';
  border-color: ${({ comment, survey }) => comment ? 'rgba(0, 0, 0, 0.09)' : survey ? '#cdcbcb' : 'none'};
  border-image: ${({ comment }) => comment ? 'initial' : 'none'};
  padding: ${({ padding }) => padding ? padding : '15px'};
  border-radius: ${({ comment }) => comment ? '0px' : '6px'};
  border-width: ${({ comment, survey }) => comment || survey ? '1px' : '0px'};
  border-style: ${({ comment, survey }) => comment || survey ? 'solid' : 'none'};
  height: ${({ height }) => height};
  flex-direction: ${({ flex }) => flex && 'row-reverse'};
  ${({ height }) => height && css`
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 6px 6px 0px 0px !important;
  `}

  >div{
  // height: ${({ isCommentOrReply }) => isCommentOrReply && "100%"};

  }
  >div:nth-child(2){
    width: 90%; 
    .
  }
  @media (max-width: 500px) {
    width: ${({ UpdateStatus }) => UpdateStatus ? '100%' : 'calc(100% - 50px)'};
  }
  .CustomTextAreaField {
    ${'' /* padding-top: 15px; */}
    line-height: 20px;
    height: 50px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    // width: calc(100% - 4px);
    width: 100%;
    color: #005c87;
    resize: none;
    float: left;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #9c9c9c;
      opacity: 1;
    } 

    :-ms-input-placeholder {
      color: #9c9c9c;
    }

    ::-ms-input-placeholder {
      color: #9c9c9c;
    }  
  }
  
  .CustomTextAreaField2 {
    padding-top: 15px;
    line-height: 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    width: calc(100% - 4px);
    color: #3A3A3A;
    resize: none;
    float: left;
    height: 80px;
    position: absolute;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #9c9c9c;
      opacity: 1;
      font-size: 18px;
      font-family: Rubik-Regular;
    }

    :-ms-input-placeholder {
      color: #9c9c9c;
      font-size: 18px;
      font-family: Rubik-Regular;
    }

    ::-ms-input-placeholder {
      color: #9c9c9c;
      font-size: 18px;
      font-family: Rubik-Regular;
    }
  }
`;

const InputTextArea = styled.div`
  position: relative;
  height: ${({ isCommentOrReply, height, peer }) => peer ? '100%' : height ? '100px' : isCommentOrReply ? '50px' : '100px'};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  >textarea{
    width: 100%;
    display: flex;
    resize: none;
    border: ${({ border }) => border};
    padding-top: ${({ border }) => border && '2px'};  
    height: ${({ border }) => border && '20px'};
    font-size: 16px;
    color: #3a3a3a;
    &:focus, &:active, &:hover, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
    
    &::placeholder {
      color: #9c9c9c;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #9c9c9c;
    }

    &::-ms-input-placeholder {
      color: #9c9c9c;
    }
  }
`;

const ShowTaggedUser = styled.div`
  margin-bottom: 5px;
  display: inline-block;  
  position:relative;
  width: 100%;
  border-radius: 5px;
  background: ${({ height }) => height ? '#f9f9f9' : '#ffffff'};
  cursor: default;
  border: ${(border) => border ? 'none' : 'solid 1px #dadada'};
  float: left;
  padding: ${(padding) => padding ? '2px 0px' : '2px 10px'};
  height: ${({ height }) => height && "39px !important"}
  font-family: ${MediumFont};
  display: flex;
  align-items: center;
  color: ${(color) => color ? '#0D4270' : '#0D4270'}; 
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-right: 5px;
  font-size: 16px;
  line-height: 20px;
  padding-left:${({ isCommentOrReply }) => isCommentOrReply && "10px"};

  >img: first-child{
    width: 22px;
    height: 22px;
    // border-radius: 50%;
    margin-right: 9px;
    margin-bottom: 2px;
    margin-left: 0px;
  }

 .tagUserText{
  display:inline-block;
  height:40px;
  width:${({ isCommentOrReply }) => isCommentOrReply ? "150px" : "150px"};
    // border:none !important;
    padding: 0px !important;
    margin-left:5px;
    resize: none !important;
    outline: 0px none transparent !important; 
    background: rgba(156, 156, 156, 0.05);
    border: 1px solid rgba(156, 156, 156, 0.5);
    border-radius: 6px;
    margin-bottom:6.5px;
  }
  
  .cross{
    // position:absolute;
    // width:15px;

    cursor: pointer;
    // top:6px;
    // right:${({ isCommentOrReply }) => isCommentOrReply ? "-135px" : "-20px"};
    margin-left: 10px;
  }
`;

const ShowTaggedUser2 = styled.div`
  margin-bottom: 5px;
  border-radius: 5px;
  border: none;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #ffffff;;
  cursor: default;
  float: left;
  padding: 2px 5px;

  font-family: Rubik-Regular;
  color: #0D4270;
  letter-spacing: 1px;
  text-transform: capitalize;
`;
const ShoutImage = styled.img`
  margin-left: 5px;
  max-width: 20px;
  max-height: 20px;
`;

const Cross = styled.img`
  margin-left: 5px;
  width: 20px;
  height: 20px;
  z-index: 999;
  cursor: pointer;
`;

const UserContainer = styled.div`
  width: 100%;
  position: absolute;
  height: auto;
  border-radius: 6px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px;
  z-index: 2;
  padding: 15px;
  display: ${({ showSuggestor }) => showSuggestor ? "block" : "none"};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;


const UserDropdownContainer = styled.div`
  // background: ${({ index, currentSelection }) => index === currentSelection ? 'rgba(156, 156, 156, 0.1)' : ''};
  : hover {
    cursor: pointer;
  }
`;

const ListDropdown = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  word-spacing: normal;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  font-weight: ${({ index, currentSelection }) => index === currentSelection ? 'bold' : 'normal'};
  color: ${({ index, currentSelection }) => index === currentSelection ? 'black' : 'black'};
`;
const ProfilePicture = styled.div`
  width: 100%;
  display: inline-block;
`;

const CustomTextAreaField = styled.textarea`
  font-size: 14px;
  border-radius: 4px;
  border: none;
  width: calc(100% - 25px);
  color: rgb(153,153,153);
  resize: none;
  float: left;
  height: 100%;
  resize: none;
  ${'' /* &:focus, &:active {
    outline: none;
  } */}
    
  ${'' /* ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  } */}

  ${'' /* :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }*/}
`;

const ContentContainer = styled.div`
  float: left;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  display: flex;
`;

const InputImage = styled(ImageInput)`
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  float: right;
  margin-bottom: 0;
  margin-top: ${({ noMarginTop }) => noMarginTop ? '5px' : '55px'}};
  > i {
    color: #dddddd;
    font-size: 25px;
  }
`;

const IconContainer = styled.div`
  display: ${({ cameraIcon }) => cameraIcon ? 'contents' : 'block'}
  width: 100%;
  height: auto;
  float: inherit;
`;

const ShoutoutDiv = styled.div`

  >div {
    padding: 13px 10px;
    margin: 0px 0;
    height: 50px;
    padding-left: 0px;width:${({ isCommentSection }) => isCommentSection && "125%"};
  }
  // display: flex;
`;

const ShoutoutContainer = styled.div`
  width: ${({ width }) => width ? width : '135px'};
  font-family: Rubik-Regular;
  margin-right: 5px;
  margin-top: 5px
  float: left;
  padding: ${({ height }) => height ? '3px 15px' : '2px 15px'};
  border-radius: 18px;
  height: ${({ height }) => height}
  border: 1px solid #dddddd;
  cursor: pointer;
  color: #423d3d;
  background: #e6e6e6;
  display: ${({ display }) => display === 1 ? 'flex' : 'block'};
  justify-content: ${({ display }) => display === 1 ? 'center' : 'none'};
    
  > img {
    vertical-align: text-bottom;
    padding-right: 10px;
    width: 30px;
  }
`;

const FeedsContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: 25px;
  background-color: white;
  border-radius: 6px;
  // -moz-box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);
  // -webkit-box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);
  // box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);

  &:first-child {
    margin-top: 0;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  float: left;
  padding: ${({ onModal }) => onModal ? '5px 15px' : '25px 25px 0'};
  display: flex;
  align-items: center;
  > img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    float: left;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 767px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }

  @media (max-width: 500px) {
    padding: ${({ onModal }) => onModal ? '0' : '25px 15px 0 15px'};
  }
`;

const ImageWrapper = styled.div`
  width: ${({ isCommentSection }) => isCommentSection ? '32px' : '50px'};
  height: ${({ isCommentSection }) => isCommentSection ? '32px' : '50px'};
  border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '50%'};
  float: left;
  cursor: pointer;
  margin-right: ${({ isCommentSection }) => isCommentSection ? '20px' : '0'};
  display: ${({ display }) => display ? '' : 'none'};

  > img {
    width: 50px;
    height: 50px;
    border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '50%'};
    float: left;
    cursor: pointer;
    object-fit: cover;
  }

  ${({ isCommentSection }) => isCommentSection && css`
    > img {
      width: 50px;
      height: 50px;
    }
  `}

  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    > img {
      width: 40px;
      height: 40px;
    }
  }
`;

const UserInformation = styled.div`
  float: left;
  display: inline-block;
  margin-left: 10px;
  line-height: 25px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: ${({ width, newWidth }) => newWidth ? "calc(100% - 200px)" : width ? 'calc(100% - 130px)' : 'calc(100% - 70px)'};
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: flex-start;
  > span {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #0D4270;
    height: 50%;
    padding-top: 5px;
    letter-spacing: 0px;
    font-family: ${BoldFont};
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  
  > div {
    font-size: 14px;
    letter-spacing: 0px;
    color: #9C9C9C;
    margin-top: 5px;
    > i {
      margin-right: 5px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    margin-right: 10px;
    width: ${({ width }) => width ? 'calc(100% - 130px)' : 'calc(100% - 70px)'};
  }

  ${({ isOwnPost }) => isOwnPost && css`
    width: calc(100% - 110px);
  `}
`;

const Description = styled.div`
  width: ${({ width }) => width ? 'auto' : '100%'};
  display: ${({ contents }) => contents ? 'inline-block' : 'block'}
  font-size: 16px;
  letter-spacing: 0px;
  color: #0D4270;
  padding: 10px 25px 0;
  margin-top: 0px;
  white-space: pre-line;
  word-break: break-word;
  font-family: ${MediumFont}
  @media (max-width: 500px) {
    padding: 0 0px;
  };

  span {
    letter-spacing: 0px;
    // color: rgb(21,159,202);
    font-family: ${MediumFont};
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
    text-transform: capitalize;
    // padding: 0px 25px 0;
  }
`;

const FeedInformation = styled.div`
  width: 100%;
  float: left;
  // border-bottom: 1px solid #e2e2e4;
  ${'' /* padding: 0 45px 25px 35px; */}

  @media (max-width: 500px) {
    padding: 0 0 25px;
    > img {
      width: 100%;
    }
  }
`;

const SingleFeedDescription = styled.div`
  margin-top: 35px;
  padding: 0 45px 0 25px;
  @media (max-width: 500px) {
    padding: 0 15px;
  };
`;

const ShoutoutImage = styled.div`
  display: inline;
  > img {
    width: ${({ comment }) => comment ? '16px' : '24px'};
    height: ${({ comment }) => comment ? '16px' : '24px'};
    margin-left: 7px;
  }
`;

const FeedFeatures = styled.div`
  float: left;
  padding: 0 25px 0px;
  width: 100%;
  margin-top: 15px;
  border-top: 1px solid #D8D8D8;
  border-top: ${({ border }) => border ? '1px solid rgba(156, 156, 156, 0.3)' : '1px solid #D8D8D8'};
  height: 40px;
  
  > div {
    float: left;
    line-height: 36px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;

  }
  
  > button {
    float: right;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  @media (max-width: 500px) {
    padding: 0 15px;
  }
`;

const CommentCounts = styled.button`
  display: inline-block;
  float: left;
  margin-left: 18px;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: white;

  > i {
    float: left;
    color: #bbbbbb;
    font-size: 16px;
    margin-right: 6px;
  }
  
  > span {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #3A3A3A;
    font-family: 'Rubik';
    float: left;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-top: 0;
    margin-right: 6px;
  }

  &:active, &:focus {
    outline: none;
  }
`;

const LikeCounts = styled(CommentCounts)`
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 25px;
    margin-right: 7px;
  }
  > i {
    color: #bbbbbb;
  }
  > span {
    font-family: ${MediumFont};
    font-size: 12px;
    line-height: 20px;
    color: #0D4270;
  }
  ${({ active }) => active && css`
    > i {
      color: #ef6827;
    }
  `}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed !important;
    opacity: 0.5;
    background-color: white;
  `}
`;

const CommentButton = styled.button`
  background-color: #159fc9;
  border: 1px solid #159fc9;
  color: white;
  white-space: nowrap;
  padding: 8px 20px;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 30px;
  text-transform: uppercase;
  font-family: ${BoldFont};

  ${({ right }) => right && css`
    float: right;
    margin-top: 15px;
  `}
  
  &:focus, &:active, &:hover {
    outline: none;
  }

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
`;

const StyledTitle = styled(ModalTitle)`
  font-size: 18px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  font-family: ${BoldFont};
  text-align: center;
`;

const StyledInput = styled.input`
  width: calc(100% - 52px);
  float: right;
  font-size: 14px;
  padding: 12px 10px;
  border-radius: 4px;
  border: none;
  color: rgb(153,153,153);
  
  &:focus, &:active {
    outline: none;
  }
      
  ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }
`;

const ImageContainer = styled.div`
  padding: 15px;
  width: 100%;
  float: left;
  
  > img {
    width: 100%;
    height: ${({ editPost }) => editPost && '264px'};
  }

  @media (max-width: 500px) {
    padding: 15px 0;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  margin: 20px 0;
`;

const SideBarContainer = styled.div`
  width: ${({ videoCSS }) => videoCSS ? '35%' : 'calc(35% - 25px)'};
  max-width: 385px;
  float: left;
  .wellnessInitiatives {
    width: 100%;
    background-color: white;
    box-shadow: 0 0 21px rgba(0,0,0,0.08);
    float: left;
    margin-bottom: 20px;
  }
  
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-left: ${({ videoCSS }) => videoCSS && '10px'}
  }
  .biometric-label {
    font-size: 12px;
    letter-spacing: 0px;
    color: rgb(255,255,255);
    margin-bottom: 20px;
    cursor: pointer;
    width: 100%; 
  }
`;

const TileContainer = styled.div`
  width: 100%;
  float: left;
  background-color: white;
  padding: ${({ padding }) => padding ? '0px' : '15px'};
  margin-top: ${({ profilePage }) => profilePage ? '0' : '30px'};
  box-shadow: ${({ padding }) => padding ? 'none' : '1px 1.732px 46px rgba(0,0,0,0.1)'};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  border-radius: ${({ padding }) => padding && '6px'};
  position: ${({ padding }) => padding && 'relative'};
  cursor: ${({ padding }) => padding && 'pointer'};
  &:first-child {
    margin-top: 0;
  }
  .bg-opacity {
    position: absolute;
    height: 225px;
    width: 100%;
    top: 0;
    left: 0;
    background: #000000;
    opacity: 0.3;
    border-radius: 6px;
  }
  .image{
    width: 41px;
    height: 41px;
    position: absolute;
    bottom: 65px;
    left: 25px;
  }
  .featured-text{
    font-family: ${MediumFont};
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
    position: absolute;
    bottom: 85px;
    left: 68px;
  }
  @media (max-width: 1235px){
    width: ${({ width }) => width ? '49%' : '100%'}
  }
  @media (max-width: 500px){
    width: 100%;
  }
`;

const TitleContent = styled.div`
  font-size: 14px;
  border-bottom: 1px solid #e2e2e4;
  width: 100%;
  float: left;
  padding: 0 10px 10px 5px;
  letter-spacing: 0px;
  line-height: 28px;
  cursor: ${({ isClickable }) => isClickable ? 'pointer' : ''};
  
  > i {
    color: #159fc9;
    font-size: 13px;
  }
  > span {
    margin-left: 8px;
  }
  
  span:nth-child(3) {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 28px;
    float: right;
    color: rgb(153,153,153);
    
    > span {
      font-family: ${BoldFont};
      color: rgb(21,159,202);
    }
  }
  
  > svg {
    width: 20px;
    height: 24px;
    padding-top: 4px;
  }
`;

const SideBarContent = styled.div`
  padding: 15px 10px 5px 5px;
  float: left;
  width: 100%;
  .inline {
    float: left;
    height: 100%;
    width: 100%;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
  > span {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
    color: rgb(153,153,153);
  }

  .image-container {
    float: left;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }

    > img {
      float: left;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: ${({ isClickable }) => isClickable && 'pointer'}
    }
  }
  
  ${(props) => props.notificationSection && css`
    border-bottom: 1px solid #dededf;
    padding: 15px 10px 15px 5px;
    height: 100%;
  `}
`;

const StyledImage = styled.div`
  float: left;
  height: 100%;
  display: table;
  width: ${({ width }) => width};

  .middle {
    height: 100%;
    display: ${({ left }) => left ? 'inline-block' : 'table-cell'};
    vertical-align: middle;
    
    img {
      width: 45px;
      height: ${({ height }) => height ? height : '45px'};
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }
  }
`;

const DropDownStyledImage = styled.div`
  float: left;
  height: 100%;
  display: table;
  width: ${({ width }) => width};
  background: rgba(156, 156, 156, 0.1);
  border-radius: 3px;
  height: 50px
  .middle {
    height: 100%;
    display: ${({ left }) => left ? 'inline-block' : 'table-cell'};
    vertical-align: middle;
    min-width: 50px;
    min-height: 40px;
    > img {
      // border-radius: 50%;
      width: 30px;
      height: ${({ height }) => height ? height : '45px'};
      float: left;
      margin: 5px 10px;
    }
  }
`;

const NotificationDescription = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  word-spacing: normal;
  display: table-cell;
  vertical-align: middle;
  .lowerCase {
    text-transform: lowercase;
    color: rgb(153,153,153);
  }
`;

const WellnessExpertImage = styled.img`
  float: left;
  width: 100%;
  cursor: pointer;
  border-radius: 6px;
  height: 225px;
`;

const WellnessExpertName = styled.div`
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 28px;
  color: rgb(51,51,51);
  font-family: ${MediumFont};
  float: left;
  width: 100%;
  text-align: left;
  padding: 15px 0 15px 35px;
  cursor: pointer;
`;

const FeaturedEventsContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: ${({ noMargin }) => noMargin ? '0' : '20px'};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
`;

const DownAngle = styled.i`
  float: right;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 45px;
  cursor: pointer;
  margin-left: 20px;
  .fas {
    margin-right: 4px;
    color: rgb(153,153,153);
  }
  .fa {
    margin-right: 4px;
    color: rgb(153,153,153);
  }  
  @media only screen and (max-width: 767px) {
    position: relative;
    right: 0px;
    margin-left: 38px;
    .btn {
      padding: 0px;
    }
  }
  .dropdown-toggle {
    border: none !important;
    background-color: white !important;
    box-shadow: none !important;
    .caret {
      display: none;
    }
    > i {
      color: #3A3A3A;
      transform: rotate(90deg);
    }
  }   
  .dropdown-menu {
    margin-left: ${({ width }) => width ? '-100px' : '-98px'};
    width: ${({ width }) => width ? 'auto' : '125px'};
    min-width: 0px !important;
    border: none;
    margin-top: -10px;
    @media(max-width: 600px) {
      margin-top: -7px;
      margin-left: -86px;
    }
    > li {
      > a {
        color: #0D4270;
        font-family: ${MediumFont};
        font-style: normal;
      }
    }
  }
`;

const NoComments = styled.div`
  float: left;
  width: 100%;
  padding: 30px;
  font-size: 15px;
  font-family: ${BoldFont};
`;

const NoActivityFeeds = styled.p`
  font-size: 14px;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgb(153,153,153);
  float: left;
  width: 100%;
  padding: 15px 0 0 5px;
`;

const Layout = styled.div`
  padding: 25px;
  margin-top: 10px;
  width: 100%;
  float: left;
  min-height: 650px;
  font-family: ${MediumFont};
  overflow: scroll;
  display: flex;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    padding: 45px 15px 20px;
  }
`;

const Profile = styled.div`
  display: inline-block;
  width: 50%;
  text-align: left;
  vertical-align: middle;

  &:last-child {
    text-align: right;
  }

  .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    > img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
  
      @media (max-width: 500px) {
        width: 40px;
        height: 40px;
      }
    }
    > span {
      text-transform: capitalize;
      font-size: 16px;
      font-family: ${BoldFont};
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
  
      @media (max-width: 500px) {
        font-size: 12px;
      }
    }
  }

  > button {
    height: 25px;
    width: 70px;
    border-radius: 4px;
    background-color: #159fc9;
    border: none;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-family: ${BoldFont};
    margin-left: 10px;

    &:last-child {
      border: 1px solid gray;
      color: gray;
      background-color: white;
    }

    @media (max-width: 500px) {
      width: 50px;
      font-size: 10px;
      padding: 0;
    }

    &:focus, &:active {
      outline: none;
    }
  }

  @media (max-width: 500px) {

    > span {
      width: calc(100% - 65px);
      text-align: left;
    }

    > button {
      &:last-child {
        margin-top: 10px;
      }
    }
  }
`;

const NoFriendRequests = styled.p`
  font-size: 14px;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgb(153,153,153);
  margin-bottom: 0;
`;

const CommentSection = styled.div`
  width: 100%;
  float: left;
  border-radius: 6px;
  padding: 10px;
  background: linear-gradient(180deg, #52ABED 0%, #8AD3F7 100%);
  >div:first-child{
    border-radius: 0;
    background: white;
    padding: 10px;
  }
`;

const ProfileContainer = styled.div`
  padding: 5px 60px;

  @media (max-width: 500px) {
    padding: 5px 0;
  }
`;

const AssessmentContainer = styled.div`
  background: linear-gradient(180deg, #37ED7D 0%, #129A8E 100%);
  width: 100%;
  font-family: ${BoldFont};
  display: block;
  color: white;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const TipInformation = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  padding: 23px 20px;
  cursor: pointer;
  > span {
    font-size: 12px;
    letter-spacing: 0px;
    color: rgb(255,255,255);
    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
`;

const InformationIcon = styled.div`
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: transparent;
  text-align: center;
  display: inline-block;
  margin-right: 9px;
  font-family: ${BoldFont};
`;

const WrapImage = styled.div`
  width: 100%;
  margin-top: 15px;
  float: left;
  padding: 0px 25px 0;          
  cursor: pointer;
  > img {
    // width: auto;
    // max-width: 100%
    // border-radius: 3px;;
    // float: left;
    // height: 426px;

    // width: 100%;
    // border-radius: 3px;
    // float: left;
    // height: 426px;
    // object-fit: cover;

    width: auto;
    max-width: 100%;
    border-radius: 3px;
    float: left;
    
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog{
    display: flex;
    justify-content: center;
    transform: ${({ transform }) => transform && 'translate(0, 50vh) translate(0, -50%) !important'};
    .modal-content{
      width: 100%;
      border-radius: 10px;
      .modal-header {
        border-bottom: ${({ noBorder }) => noBorder ? noBorder : '1px solid #e5e5e5'};

        > div {
          display: flex;
          justify-content: center;

          > p{
            font-size: 20px;
            font-family: ${BoldFont};
            margin-top: 10px 0 0 0;
          }
        }
      }
      .modal-header .close {
        margin-top: -2px;
        outline: none;
      }
    }
  }
  @media (max-width: 500px) and (min-width: 320px) {
    .modal-dialog {
      width: 100%;

      .modal-content{
        width: 90%;
        margin-left: -6%;
      }
    }
  }
`;

const EmojiContainer = styled.div`
padding: 10px;
width: 100%;
display: ${({ length }) => length === 0 ? 'flex' : 'grid'};
gap: 10px;
grid-template-columns: repeat(2, 35%);
grid-template-rows: repeat(${({ length }) => length ? length : '3'}, 55px);
grid-auto-flow: row;
font-family: ${({ length }) => length === 0 ? `${BoldFont}` : ` ${RegularFont}`};
color: ${({ length }) => length === 0 ? '#a8a5a5' : 'none'};
font-size: ${({ length }) => length === 0 ? '16px' : '14px'};
justify-content: ${({ length }) => length === 0 ? 'center' : 'space-evenly'};
> div {
    font-family: ${RegularFont};
    cursor: pointer;
    float: left;
    display: grid;
    grid-template-columns: 20% 90%;
    gap: 10%;
    height: 50px;
    padding: 5px 5px 5px 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  }

  @media (max-width: 500px) and (min-width: 320px) {
    grid-template-columns: repeat(2, 55%);
    > div {
      width: 90%;
      margin-left: 10px;
      padding: 7px 5px 5px 5px;
      gap: 7%;
      grid-template-columns: 30% 90%;
    }
  }

`;

const Text = styled.p`
  margin: 10px 0 0px;
  font-family: ${RegularFont};

  @media (max-width: 500px) and (min-width: 320px) {
    font-size: 11px;
  }
`;
const ImageWrap = styled.div`
width: 40px;
height: 40px;
border-radius: 100%;
display: flex;
justify-content: center;
  > img {
    width: 50%;
    height: 50%;
    margin-top: 10px;
  }

  @media (max-width: 500px) and (min-width: 320px) {
    width: 35px;
    height: 35px;
  }
`;
const RecognitionSection = styled.div`
width: ${({ width }) => width ? width : '100%'};
display: flex;
height: ${({ height }) => height ? height : '50px'};
align-items: center;
padding: 0;
box-sizing: border-box;
border-radius: 0px 0px 6px 6px;
justify-content: ${({ display }) => display ? display : 'space-between'};
float: ${({ float }) => float};
> div: first-child {
  display: flex;
  > div {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    ${'' /* border: 1px solid #F3F3F3; */}
    border-radius: 4px;
    box-sizing: border-box;
    margin-right: 10px;
    cursor: pointer;
    > img {
      width: 24px;
      position: relative;
    }
    > input {
      opacity: 0;
      position: absolute;
      width: 25px;
      height: 25px;
    }
  }
}
> button {
  // width: 34px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  padding: 0 10px;
  // background: #F9FAFB;

  > img {
    width: 24px;
  }
}

@media(min-width: 1400px) {
  width: ${({ reply }) => reply ? '27%' : '100%'};
}
@media(min-width: 1500px) {
  width: ${({ reply }) => reply ? '25%' : '100%'};
}
`;
const AddBuddyContainer = styled.div`
min-width: 120px;
height: 30px;
margin: auto;
margin-right: 0px;
> button {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 3px;
  color: #FFF;
  background-color: #FD7175;
  font-size: 16px;
  line-height: 2px;
  font-family: 'Rubik-Regular';
  outline: none;
}
`;

const PendingButton = styled.div`
  width: 120px;
  height: 30px;
  background-color:#f6b479;
  outline: none;
  border: navajowhite;
  padding: 2px;
  border-radius: 3px;
	cursor: pointer;
	display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 16px;
  font-family: 'Rubik-Regular';
  line-height: 2px;
  margin: auto;
  margin-right: 0px;
`

const RemoveBuddyContainer = styled.div`
cursor: pointer;
> img {
  width: 15px;
}
`;
const RemoveButtonContainer = styled.div`
padding-top: 15px;
width: 120px;
> button {
  height: 30px;
  width: 100%;
  outline: none;
  border: none;
  background-color: #FFF;
  color: #000000;
  font-size: 16px;
  line-height: 2px;
  font-family: 'Rubik';
  box-shadow: rgb(0 0 0 / 19%) 0px 2px 2px, rgb(0 0 0 / 7%) 0px 4px 6px;
  border-radius: 4px;
}
`;

const PendingButtonContainer = styled.div`
  padding-top: 15px;
  width: 35%;
  > button {
    height: 41px;
    width: 100%;
    outline: none;
    border: none;
    background-color: #FFF;
    color: #000000;
    font-size: 18px;
    line-height: 2px;
    font-family: 'Rubik';
    box-shadow: rgb(0 0 0 / 19%) 0px 2px 2px, rgb(0 0 0 / 7%) 0px 4px 6px;
    border-radius: 4px;
  }
`;


const TimeOfPost = styled.div`
letter-spacing: 0px;
padding: 0 45px 0 25px;
color: rgb(153,153,153);
font-size: 14px;
margin-top: 10px;
`;
const EmojiContainerV2 = styled.div`
z-index: 1;
float: left;
position: relative;
display: flex;
align-items: center;
flex-wrap: wrap;
height: 100%;
width: 100%;
outline: none;
border: none;
color: #000000;
font-size: 18px;
line-height: 2px;
font-family: 'Rubik';
background: #FFFFFF;
box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.18);
border-radius: 0px 6px 6px 6px;
margin-top: 27px;
margin-left: -5px;
padding: 10px;
	> div {
		background: #F5F5F5;
		border-radius: 25px;
		margin: 10px;
		cursor: pointer;
		width: 45px;
    height: 45px;
    justify-content: center;
    display: flex;
		align-items: center;

		> img {
			width: 24px;
		}
	}
`;

const PhotoWrapper = styled.div`
  width: 33%;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-right: 1px solid #EAEAEA;
  >img{
    width: 32px;
  }
  >p{
    margin: 0 0 0 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #3E3F42;
    line-height: 12px;
  }
`;

const ButtonWrapper = styled.div`
    height: 60px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 4%);
    border-radius: 0px 0px 6px 6px;
    background: #F7F7F7;
    width: 100%;
    float: left;
    display: flex;
`;

const PostWrapper = styled.div`
  width: 33%;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-right: 1px solid #EAEAEA;
  border-radius: 0 0 0 6px;
  >img{
    width: 30px;
    height: 30px;
  }
  >p{
    margin: 0 0 0 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #3E3F42;
    line-height: 12px;
  }
`;

const RecognitionWrapper = styled.div`
  width: 34%;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-radius: 0 0 6px 0px;
  >img{
    width: 30px;
    height: 30px;
  }
  >p{
    margin: 0 0 0 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #3E3F42;
    line-height: 18px;
  }
`;

const LinkContainer = styled.div`
    width: 100%;
    height: 55px;
    float: left;
    margin-bottom: 35px;
    background: rgb(138, 211, 247);
    border-radius: 0 0 6px 6px;
    padding: 10px;
`;

const Link = styled.div`
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    display: flex;
    padding: 0 10px;
`;

const LinkTab = styled.div`
    width: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    line-height: 21px;
    text-align: center;
    color: ${({ active }) => active ? '#0D4270' : '#8B8B8B'};
    border-bottom : ${({ active }) => active ? '3px solid #0D4270' : 'none'};
    font-family: ${MediumFont};
    font-style: normal;
    font-weight: normal;
`;

const PictureDiv = styled.div`
  width: 10%;
  >img{
    width: 50px;
    height: 50px;
    border-radius: 3px;
  }
`;

const MainSend = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 10px;
  >img{
    width: 42px;
    height: 42px;
  }
`;

const BottomSection = styled.div`
  width: 100%;
  padding: 15px 0px 0px 0px;
  display : flex;
  .imageContainer {
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    >img{
      width: 100%;
      max-width: 450px;
      height: auto;
    }
    .remove{
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
`;

const LeftContent = styled.div`
    width: 100%;
    etter-spacing: 0px;
    line-height: 24px;
    color: rgb(51,51,51);
    font-family: "Rubik-Regular";
    > span.title {
      font-size: 18px; 
      color: #0D4270;     
    }
    >div{
      text-align: left;
      margin-top: 0;
      color: rgb(153,153,153);
      text-transform: capitalize;
      >textarea{
        padding: 15px 10px;
        resize: none;
        width: 100%;
        font-size: 16px;
        border: none;
        height: 65px;
        color: #3a3a3a;
        &:focus-visible {
          border: none;
          outline: none;
        }
      }
    }
`

const LikedUser = styled.div`
  display: flex;
`

const Text2 = styled.div`
  // font-size: 14px;
  font-family: ${MediumFont};
`

const SelectedImage = styled.div`
>img{
  width: 150px;
}
`

const FeedTab = styled.div`
  background: #F8F8F8;
  border: 0.1px solid #EAEAEA;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;
  background: ${({ background }) => background ? background : '#F8F8F8'};
`

const LikeButton = styled.div`
  padding: 10px;
  justify-content: center;
  display: flex;
  width: 33%;
  align-items: center;
  cursor: pointer;
  >div:first-child{
    >img{
      height: 32px;
      width: 32px
    }
  }

  >div:last-child{
    padding-left: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #3A3A3A;
  }
`

const CommentTabButton = styled.div`
  padding: 10px;
  // border: 0.1px solid #EAEAEA;
  // border-top: none;
  // border-bottom: none;
  justify-content: center;
  display: flex;
  width: 33%;
  align-items: center;
  cursor: pointer;
  >div:first-child{
    >img{
      height: 32px;
      width: 32px
    }
  }

  >div:last-child{
    padding-left: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #3A3A3A;
  }  
`

const RecognitionButton = styled.div`
  padding: 10px;
  justify-content: center;
  display: flex;
  width: 34%;
  align-items: center;
  cursor: pointer;
  border: 0.1px solid #EAEAEA;
  border-top: none;
  border-bottom: none;
  >div:first-child{
    >img{
      height: 32px;
      width: 32px
    }
  }

  >div:last-child{
    padding-left: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #3A3A3A;
  }
`

const FieldContainer = styled.div`
padding: 0;
border: 1px solid #EAEAEA;
border-top: ${({ borderTop }) => borderTop ? '0px' : '1px solid #EAEAEA'};
border-bottom: ${({ borderTop }) => borderTop ? '1px solid rgba(156, 156, 156, 0.3)' : '1px solid #EAEAEA'};
box-sizing: border-box;
border-radius: 0px;
float: ${({ noFloat }) => noFloat ? 'none' : 'left'};
width: 100%;
position: relative;
${({ noFloat }) => noFloat && css`
  padding: 34px 0 20px 0;
  @media (max-width: 500px) {
    padding: 0;
  }
`}

>div:first-child{
  padding-left: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 25px;
  // justify-content: space-around;
  
  >div:first-child{
    width: 50px;
    height: 50px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    >img{
      // background: url(Image.png), url(Image.png), url(.png), url(Image.png), #9c9c9c;
      background-blend-mode: normal, normal, normal, normal, normal;
      border-radius: 3px;
      height: 50px;
      width: 50px;
    }
  }
  >div:nth-child(2){
    width: 100%;
    padding-left: 0;
    // border: none;
    border: 1px solid #9C9C9C;
    border-radius: 6px !important;
    background: rgba(156, 156, 156, 0.05);
    >div:first-child{
      float: left;
      width: 100%;
      >div:first-child{
        width: 100%;
      }
      >div:last-child{
        width: 100%;
        >div:first-child{
          width: ${({ width }) => width ? '69%' : '100%'};
          width: ${({ isCommentOrReply }) => isCommentOrReply && "100%"};

          
          >textarea{
            padding: 15px 15px;
            resize: none;
          }
        }
      }
    }
  }

  >div:last-child{
    justify-content: center;
    display: flex;
    align-items: center;
    >div:first-child{
      >img{
        width: 32px;
      }
    }

    >div:last-child{
      margin-left: 25px;
      margin-left: ${({ isCommentOrReply }) => isCommentOrReply && "0px"};
      display: flex;
      width: 26%
      padding-right: 8px
      >img{
        width: 32px;
        height: 32px;
      }
    }
  }

}

>div:last-child{
  display : none;
}

> span: first-child {
  display: none;
}
> span {
  font-size: 24px;
}

&:last-child {
  border-bottom: 1px solid #EAEAEA;
  border-bottom: ${({ borderTop }) => borderTop ? '1px solid rgba(156, 156, 156, 0.3)' : '1px solid #EAEAEA'};
}

${(props) => props.fixHeight && css`
  border-bottom: none;
`}

${({ socialPage }) => socialPage && css`
  > span {
    font-size: 18px;
    float: left;
    padding: 20px 0 10px;
  }
`}

@media (max-width: 500px) {
  padding: 10px;
}
`;

const Option = styled.div`
margin-bottom: 5px;
    margin-top: auto;
  >div{
    cursor: pointer;
    margin-right: 11px;
  }
`

const RecognitionText = styled.div`
  width: 100%;
  padding: 10px 25px 0;
  padding-left: ${({ padding }) => padding ? padding : '25px'};
  .first-span{
    font-family: Rubik !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #0D4270; 
    >img{
      width: 20px;
      height: 20px;
      margin: 0 8px 9px;
    }
    .recognition-name{
      font-family: rubik-medium;
    }
  }

  .second-span{
    font-family: Rubik-Medium !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: #0D4270;
    margin-left: 5px;
    >span span{
      font-family: Rubik !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: justify;
      color: #3E3F42;
      margin-left: 5px;
    }
  }
  .CommentDescription {
    display: block;
    width: 100%;
    word-break: break-word;
    text-transform: none !important;
    text-align: justify;
    font-size: 16px;
    line-height: 18px;
    color: #3A3A3A;
    font-family: Rubik-Regular !important;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
`

const ProfileImg = styled.div`
>img{
    width: 28px;
    height: 28px;
    margin: ${({ margin }) => margin};
    border-radius: 50%;
}`

const ConnectDevice = styled.div`
width: 100%;
height: 50px;
margin-bottom: 25px;
> button {
  width: 100%;
  height: 100%;
  font-family: ${BoldFont};
  font-size: 18px;
  background-color: rgb(253,113,117);
  color: #FFF;
  padding: 4px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: stretch;
  outline: none;
  > img {
    width: 15%;
    height: 100%;
    margin-right: 12px;
    float: left;
  }
  > div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 14%;
    > span {
    }
  }
}
@media(max-width: 766px){
  > button {
    > img {
      width: 8%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-left: 0%;
    }
  }
}
@media(max-width: 500px){
  > button {
    > img {
      width: 12%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-right: 25%;
      padding-left: 14%;
    }
  }
}
`;
const CommentContent = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
  height: ${({ height }) => height ? '150px' : '50px'};
 padding-bottom: ${({ height }) => height && "20px"};
  
  > img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    float: left;
    border-radius: 50%;
    
    @media (max-width: 500px) {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
  
  ${(props) => props.isReply && css`
    padding: 0 70px;
  `}

  .ImageDiv {
    position: relative;
    right: -82px;
    top: -52px;
    >img {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      float: left;
      border-radius: 50%;
    }
  }

  .InputImageSection {
    float: left;
    left: 80px;
    top: -125px;
    >img {
      width: 100px;
      height: 100px;
      margin-right: 10px;
      float: left;
    }
  }

  .InputImageSpan {
    color: #333339;
  }

  >div:nth-child(2){
    padding: 0 0 0 15px;
    padding-bottom: ${({ height }) => height && '15px'}
    >div{
      width: 100%;
    }
  }
`;

const CommentDiv = styled.div`
  padding: 20px;
  float: left;
  width: 100%;
  >div{
    cursor:pointer;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
    text-align: justify;
    color: #3A3A3A;
  }
`

const CommentSectionNew = styled.div`
  width: 100%;
  float: left;
  border-radius: 6px;
  // padding: 8px;
  background: white;
  border: 1px solid rgba(156, 156, 156, 0.3);
  >div:first-child{
    border-radius: 0;
    background: white;
    padding: 8px;
    border-bottom: 1px solid rgba(156, 156, 156, 0.3);
  }
  >div:last-child{
    padding: 8px 10px;
  }
`;

const InputFieldContainerNew = styled.div`
  width: ${({ UpdateStatus, survey, width }) => UpdateStatus || survey || width ? '100%' : 'calc(100% - 58px)'};
  float: left;
  display: flex;
  // background: white;
  // -moz-box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  // -webkit-box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  // box-shadow: ${({ UpdateStatus }) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none'};
  // height: 'auto';
  border-color: ${({ comment, survey }) => comment ? 'rgba(0, 0, 0, 0.09)' : survey ? '#cdcbcb' : 'none'};
  border-image: ${({ comment }) => comment ? 'initial' : 'none'};
  // padding: ${({ padding }) => padding ? padding : '15px'};
  border-radius: ${({ comment }) => comment ? '0px' : '6px'};
  border-width: ${({ comment, survey }) => comment || survey ? '1px' : '0px'};
  border-style: ${({ comment, survey }) => comment || survey ? 'solid' : 'none'};
  // height: ${({ height }) => height};
  flex-direction: ${({ flex }) => flex && 'row-reverse'};
  cursor: pointer;
  ${({ height }) => height && css`
    border: 1px solid #F3F3F3;
    box-sizing: border-box;
    border-radius: 6px 6px 0px 0px !important;
  `}

  >div:nth-child(2){
    width: calc(100% - 60px);
    border-radius: 6px;
    border: 1px solid rgba(156, 156, 156, 0.3);
  }
  @media (max-width: 500px) {
    width: ${({ UpdateStatus }) => UpdateStatus ? '100%' : 'calc(100% - 50px)'};
  }
  .CustomTextAreaField {
    ${'' /* padding-top: 15px; */}
    line-height: 20px;
    height: 50px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    // width: calc(100% - 4px);
    width: 100%;
    color: #005c87;
    resize: none;
    float: left;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #9c9c9c;
      opacity: 1;
    } 

    :-ms-input-placeholder {
      color: #9c9c9c;
    }

    ::-ms-input-placeholder {
      color: #9c9c9c;
    }  
  }
  
  .CustomTextAreaField2 {
    padding: 10px;
    padding-top: 12px;s
    line-height: 20px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    width: calc(100% - 4px);
    color: #3A3A3A;
    resize: none;
    float: left;
    height: 50px;
    position: absolute;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    ${'' /* padding: 0; */}
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: #9c9c9c;
      opacity: 1;
      font-size: 18px;
      font-family: Rubik-Regular;
    }

    :-ms-input-placeholder {
      color: #9c9c9c;
      font-size: 18px;
      font-family: Rubik-Regular;
    }

    ::-ms-input-placeholder {
      color: #9c9c9c;
      font-size: 18px;
      font-family: Rubik-Regular;
    }
  }
`;

const PictureDivNew = styled.div`
  width: 50px;
  margin-right: 10px;
  >img{
    width: 50px;
    height: 50px;
    border-radius: 3px;
  }
`;

const InputTextAreaNew = styled.div`
  position: relative;
  // height: ${({ isCommentOrReply, height, peer }) => peer ? '100%' : height ? '100px' : isCommentOrReply ? '50px' : '100px'};
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  >textarea{
    width: 100%;
    display: flex;
    resize: none;
    border: ${({ border }) => border};
    padding-top: ${({ border }) => border && '2px'};
    height: ${({ border }) => border && '20px'};
    font-size: 16px;
    color: #3a3a3a;
    &:focus, &:active, &:hover, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
    
    &::placeholder {
      color: #9c9c9c;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: #9c9c9c;
    }

    &::-ms-input-placeholder {
      color: #9c9c9c;
    }
  }
`;

const ButtonWrapperNew = styled.div`
    height: 60px;
    border-radius: 0px 0px 6px 6px;
    background: white;
    width: 100%;
    float: left;
    display: flex;
    padding: 0px 30px;
`;

const PostWrapperNew = styled.div`
width: 33.33%;
align-items: center;
display: flex;
justify-content: center;
cursor: pointer;
// border-right: 1px solid #9c9c9c;
border-right: ${({ border }) => border ? '1px solid rgba(156, 156, 156, 0.3)' : ''};
border-radius: 0 0 0 6px;
padding: 0px 20px
>div{
  display: flex;
  justify-content: center;
  width: 100%;
  // background: rgba(156, 156, 156, 0.1);
  padding: 10px 0px;
  align-items: center;
  border-radius: 6px;
  &:focus, &:active, &:hover, &:focus-visible {
    background: rgba(156, 156, 156, 0.1);
  }
  >img{
    width: 24px;
    height: 24px;
  }
  >p{
    margin: 0 0 0 10px;
    font-family: rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #0D4270;
    line-height: 12px;
    width: ${({ width }) => width ? '63%' : ''};
    line-height: 20px;
  }
}
`;

const PhotoWrapperNew = styled.div`
  width: 33%;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-right: 1px solid #9c9c9c;
  margin: 0px 20px
  >img{
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  >p{
    margin: 0 0 0 10px;
    font-family: rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #0D4270;
    line-height: 12px;
  }
`;

const RecognitionWrapperNew = styled.div`
  width: 34%;
  align-items: center;
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-radius: 0 0 6px 0px;
  margin: 0px 20px
  >img{
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  >p{
    margin: 0 0 0 10px;
    font-family: rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #0D4270;
    line-height: 12px;
  }
`;

const LikeButtonew = styled.div`
  // padding: 10px;
  justify-content: center;
  display: flex;
  width: 33.33%;
  align-items: center;
  cursor: pointer;
  margin: 8px 0px;
  border-right: ${({ border }) => border ? '1px solid rgba(156, 156, 156, 0.3)' : ''};
  >div:first-child{
    width:100%;
    margin: 0px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    border-radius: 6px;
    &:focus, &:active, &:hover, &:focus-visible {
      background: rgba(156, 156, 156, 0.1);
    }
  >div:first-child{
    >img{
      height: 24px;
      width: 24px
    }
  }

  >div:last-child{
    padding-left: 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #0D4270;
  }
}
`
const Border = styled.div`
  width: 100%;
  height: 1px;
  background: #9C9C9C;
  margin-bottom: 25px;
}
`

const MainSendNew = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
  >img{
    width: 42px;
    height: 42px;
  }
`;

const FeedTabNew = styled.div`
  background: #F8F8F8;
  border-top: 1px solid rgba(156, 156, 156, 0.3);
  border-bottom: 1px solid rgba(156, 156, 156, 0.3);
  box-sizing: border-box;
  border-radius: 0px;
  display: flex;
  background: ${({ background }) => background ? background : '#F8F8F8'};
  width: 100%;
  `;

const NoPosts = styled.div`
  float: ${({ addPadding }) => addPadding ? 'none' : 'left'};
  width: 100%;
  font-size: 15px;
  font-family: ${BoldFont};
  padding-top: ${({ addPadding }) => addPadding ? '35px' : 0};
`;

export {
  ShoutoutImage, SingleFeedDescription, ShoutoutDiv, ShoutImage, Cross, ShowTaggedUser, DropDownStyledImage, InputTextArea, UserContainer, UserDropdownContainer, ListDropdown, ProfilePicture, IconContainer, ShoutoutContainer, SocialFeedsContainer, CustomTextAreaField, ContentContainer, InputFieldContainer, InputImage, FeedsContainer, HeaderContainer, UserInformation,
  Description, FeedInformation, FeedFeatures, CommentCounts, LikeCounts, CommentButton, StyledTabContainer, StyledTitle, StyledInput, ImageContainer,
  ButtonContainer, SideBarContainer, TileContainer, TitleContent, SideBarContent, StyledImage, NotificationDescription, WellnessExpertImage,
  WellnessExpertName, FeaturedEventsContainer, DownAngle, NoComments, Layout, Profile, NoFriendRequests, NoActivityFeeds,
  CommentSection, ScrollableFeedsContainer, AssessmentContainer, TipInformation, InformationIcon, ProfileContainer, ImageWrapper, WrapImage,
  StyledModal, EmojiContainer, ImageWrap, Text, RecognitionSection, AddBuddyContainer, RemoveBuddyContainer, RemoveButtonContainer, PendingButtonContainer,
  TimeOfPost, EmojiContainerV2, ButtonWrapper, PostWrapper, PhotoWrapper, RecognitionWrapper, LinkContainer, Link, LinkTab, PictureDiv, MainSend, BottomSection, LeftContent, ProfileImg
  , LikedUser, Text2, SelectedImage, FeedTab, LikeButton, CommentTabButton, RecognitionButton, FieldContainer, Option, RecognitionText, ShowTaggedUser2, ConnectDevice, PendingButton, CommentDiv, CommentContent, CommentSectionNew, InputFieldContainerNew, PictureDivNew, InputTextAreaNew, ButtonWrapperNew, PostWrapperNew, PhotoWrapperNew, RecognitionWrapperNew, LikeButtonew, Border, MainSendNew, FeedTabNew, NoPosts
};

